<template>
  <div class="login-bg" v-if="bind.boolCarregado">
    <!--ALTERAR SENHA-->
    <ed-modal v-model="bind.boolExibirCadastro" width="50%">
      <div slot="title">Alterar Senha</div>

      <div class="" slot="content">
        <ed-form
          :recaptcha="$root.$session.isProduction"
          :formData="formData"
          :handlerSave="alterarSenha"
          :iconButton="$utilidade.getIcone('restaurar')"
          labelButton="Alterar Senha"
        >
          <ed-input-text
            v-if="bind.boolExibirCampoCliente"
            v-model="formData.strCliente"
            name="strCliente"
            label="Nome da Clínica"
            :icon-left="$utilidade.getIcone('empresa')"
            rules="required"
          />

          <ed-input-select
            v-model="formData.strIdentificacao"
            name="strIdentificacao"
            label="Identificação do Usuário"
            :items="[
              { intId: 'strLogin', strNome: 'POR USUÁRIO' },
              { intId: 'strCpf', strNome: 'POR CPF' },
            ]"
            rules="required"
          />

          <ed-input-text
            v-if="formData.strIdentificacao == 'strLogin'"
            v-model="formData.strLogin"
            name="strLogin"
            label="Informe seu Login"
            :icon-left="$utilidade.getIcone('usuario')"
            rules="required"
          />

          <ed-input-text
            v-if="formData.strIdentificacao == 'strCpf'"
            v-model="formData.strCpf"
            name="strCpf"
            label="Informe seu CPF"
            :icon-left="$utilidade.getIcone('usuario')"
            rules="required|cpf"
            v-mask="'999.999.999-99'"
          />
        </ed-form>
      </div>
    </ed-modal>
    <v-app>
      <v-container fluid fill-height>
        <v-layout align-center justify-center>
          <v-flex xs12 sm8 md4>
            <v-card class="elevation-12">
              <v-card-text>
                <div class="login-logo col-12 pa-0 ma-0 text-center">
                  <img :src="$root.$session.cliente && $root.$session.cliente.strUrlLogo ? this.$root.$session.cliente.strUrlLogo : '/logo.png'" />
                  <span v-if="$root.$session.cliente">{{ $root.$session.cliente.strNome }}</span>
                  <span v-if="!$root.$session.cliente">EDOLMED</span>
                </div>

                <ed-form
                  :recaptcha="$root.$session.isProduction"
                  :formData="formData"
                >
                  <ed-input-text
                    v-if="bind.boolExibirCampoCliente"
                    v-model="formData.strCliente"
                    name="strCliente"
                    label="Nome da Clínica"
                    :icon-left="$utilidade.getIcone('empresa')"
                    rules="required"
                  />

                  <ed-input-text
                    v-model="formData.strLogin"
                    name="strLogin"
                    label="Login"
                    :icon-left="$utilidade.getIcone('usuario')"
                    rules="required"
                  />
                  <ed-input-text
                    v-model="formData.strSenha"
                    name="strSenha"
                    label="Senha"
                    type="password"
                    :icon-left="$utilidade.getIcone('senha')"
                    rules="required"
                  />
                </ed-form>
              </v-card-text>
              <v-card-actions class="flex-column text-center">
                <v-spacer></v-spacer>
                <ed-button
                  color="primary"
                  label="Entrar"
                  @click="login"
                  :icon-left="$utilidade.getIcone('login')"
                  :loading="bind.boolLoadingLogin"
                  :disabled="
                    !formData.recaptcha && $root.$session.isProduction
                      ? true
                      : false
                  "
                />
                <v-spacer></v-spacer>
                <ed-button
                  class="mt-2 mb-2"
                  color="primary"
                  label="Recuperar minha senha"
                  :icon-left="$utilidade.getIcone('recuperarSenha')"
                  @click="bind.boolExibirCadastro = true"
                  text
                />
              </v-card-actions>
            </v-card>
          </v-flex>
        </v-layout>
      </v-container>
    </v-app>
  </div>
</template>

<script>
import {
  EdInputText,
  EdButton,
  EdModal,
  EdForm,
  EdInputSelect,
} from "@/components/common/form";

export default {
  name: "pageIndex",
  props: {},
  components: { EdForm, EdInputText, EdModal, EdButton, EdInputSelect },
  mounted() {},
  created() {

    this.$root.$session.layout = "cliente";


    if (
      this.$cookie.get(process.env.VUE_APP_CLIENTE_COOKIE_NAME) &&
      !this.$route.params?.strUrlDatabase
    ) {
      this.$router.push({
        name: "index",
        params: { strUrlDatabase: this.$cookie.get("customer_name") },
      });
    } else {
      this.bind.boolCarregado = true;
      this.$store.state.common.loadingContent = false;

      if (!this.$root.$session.cliente) {
        this.bind.boolExibirCampoCliente = true;
      }
    }
  },
  data() {
    return {
      bind: {
        boolCarregado: false,
        boolLoadingLogin: false,
        boolExibirCampoCliente: false,
        boolExibirCadastro: false,
      },
      formData: {
        strLogin:
          this.$route.query && this.$route.query.strLogin
            ? this.$route.query.strLogin
            : null,
        strSenha: null,
        strIdentificacao: "strLogin",
        strCpf: null,
        strNome: null,
        recaptcha: null,
        strCliente: this.$root.$session.cliente
          ? this.$root.$session.cliente.strUrlDatabase
          : null,
      },
    };
  },
  methods: {
    alterarSenha() {
      this.$root.$dialog.loading(true);

      this.$root.$request
        .put( (!this.$root.$session.cliente ? this.formData.strCliente + "/" : "") +"publico/usuario-senha/alterar", this.formData)
        .then((objResponse) => {
          this.$root.$dialog.loading(false);

          if (objResponse.status == 200 && objResponse.result) {
            this.bind.boolExibirCadastro = false;
            this.$root.$dialog.success(objResponse.result);
          } else {
            this.$root.$dialog.error(objResponse.msg);
          }
        });
    },

    login() {
      if (!this.formData.strCliente) {
        this.$root.$message.error("Por favor informe o nome do cliente");
        return;
      }

      if (!this.formData.strLogin) {
        this.$root.$message.error("Por favor informe seu login");
        return;
      }

      if (!this.formData.strSenha) {
        this.$root.$dialog.error("Por favor informe sua senha");
        return;
      }

      this.bind.boolLoadingLogin = true;

      this.$root.$request
        .post(
          (!this.$root.$session.cliente ? this.formData.strCliente + "/" : "") +
            "publico/login",
          this.formData
        )
        .then((objResponse) => {
          this.bind.boolLoadingLogin = false;

          if (objResponse.status == 200) {
            if (!objResponse.result || !objResponse.result.objUsuario) {
              this.$root.$dialog.error(
                "Seu acesso não pode ser efetuado. Por favor entre em contato com o administrador do sistema"
              );
            } else {
              this.$root.$session.cliente = objResponse.result.objCliente;
              this.$root.$session.login(
                objResponse.result.objUsuario,
                objResponse.result.strToken
              );
            }
          } else {
            this.$root.$dialog.error(objResponse.msg);
          }
        });
    },
  },
};
</script>
